<template>
  <Settings 
  :page='"app"'/>
</template>

<script>
import Settings from "../components/Settings.vue";

export default {
  components: {
    Settings
  }
}
</script>
